import React, { useState, useEffect } from "react";
//import { Dropdown } from 'react-bootstrap';
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import URL from "./services/Urls";
import swal from "sweetalert";

const options2 = [
	{ value: "Pagado", label: "Pagado" },
	{ value: "Pendiente", label: "Pendiente" },
];
const EditPayment = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const [customers, setCustomers] = useState([]);
	const [payment, setPayment] = useState({
		customerID: "",
		amount: "",
		status: "Pagado",
	});

	useEffect(() => {
		getCustomers();
		getPayment();
	}, []);

	const getPayment = async () => {
		try {
			const response = await fetch(URL.apiUrl + "/getPayment", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ userID: id }),
			});

			if (!response.ok) {
				throw new Error("Error en la respuesta de la red");
			}

			const result = await response.json();
			if (response.status === 200) {
				console.log("RESP PAYMENT", result.data);
				setPayment(result.data);
			}
		} catch (err) {
			console.log("ERROR CUSTOMERS", err);
		}
	};

	const getCustomers = async () => {
		try {
			const response = await fetch(URL.apiUrl + "/getList", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ table: "customers" }),
			});

			if (!response.ok) {
				throw new Error("Error en la respuesta de la red");
			}

			const result = await response.json();
			if (response.status === 200) {
				const formattedCustomers = result.data.map((customer) => ({
					value: customer.id,
					label: customer.name,
				}));
				setCustomers(formattedCustomers);
			}
		} catch (err) {
			console.log("ERROR CUSTOMERS", err);
		}
	};

	const handleChange = (e) => {
		setPayment({ ...payment, [e.target.name]: e.target.value });
	};

	const handleStatusChange = (selectedOption) => {
		setPayment({ ...payment, status: selectedOption.value });
	};

	const handleNameChange = (selectedOption) => {
		setPayment({ ...payment, customerID: selectedOption.value });
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			const response = await fetch(URL.apiUrl + "/updatePayment", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					paymentID: payment.id,
					customerID: payment.customerID,
					amount: payment.amount,
					status: payment.status,
				}),
			});

			const result = await response.json();
			console.log("PAYMENT UPDATED", result);

			if (response.status === 200) {
				swal("Felicidades", result.message, "success");
				navigate("/logged/payments");
			} else {
				console.error("Error al actualizar pago");
			}
		} catch (error) {
			console.error("Hubo un problema con la solicitud fetch:", error);
		}
	};

	return (
		<>
			<div className="row">
				<div className="col-xl-8 col-lg-8" style={{ margin: "auto" }}>
					<div className="card profile-card card-bx m-b30">
						<div className="card-header">
							<h6 className="title">Editar Pago</h6>
						</div>
						<form className="profile-form" onSubmit={handleSubmit}>
							<div className="card-body">
								<div className="row">
									<div className="col-sm-6 m-b30">
										<label className="form-label">Nombre</label>
										<Select
											name="name"
											options={customers}
											className="custom-react-select"
											value={customers.find((c) => c.value === payment.customerID)}
											isSearchable={false}
											onChange={handleNameChange}
										/>
									</div>

									<div className="col-sm-6 m-b30">
										<label className="form-label">Monto</label>
										<input
											name="amount"
											type="text"
											className="form-control"
											defaultValue={payment.amount}
											onChange={handleChange}
										/>
									</div>

									<div className="col-sm-6 m-b30">
										<label className="form-label">Estado</label>
										<Select
											name="status"
											options={options2}
											className="custom-react-select"
											value={options2.find((option) => option.value === payment.status)}
											isSearchable={false}
											onChange={handleStatusChange}
										/>
									</div>
								</div>
							</div>
							<div className="card-footer">
								<button className="btn btn-primary">Guardar</button>
								<button
									className="btn btn-secondary"
									onClick={() => {
										navigate("/logged/payments");
									}}>
									Atras
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	);
};
export default EditPayment;
