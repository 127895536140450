import React, { useState, useEffect } from "react";
//import { Dropdown } from 'react-bootstrap';
import { useParams, useNavigate } from "react-router-dom";
import Select from "react-select";
import URL from "./services/Urls";
import swal from "sweetalert";

import user from "./assets/face3.png";

const EditProfile = () => {
	const { id } = useParams();
	const navigate = useNavigate();

	const [customer, setCustomer] = useState({
		name: "",
		email: "",
		password: "",
		status: "",
	});

	useEffect(() => {
		getcustomer();
	}, []);

	const getcustomer = async () => {
		console.log("ID", id);
		try {
			const response = await fetch(URL.apiUrl + "/getCustomer", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ userID: id }),
			});

			if (!response.ok) {
				throw new Error("Error en la respuesta de la red");
			}

			const result = await response.json();
			if (response.status === 200) {
				setCustomer(result.data);
			}
		} catch (err) {
			console.log("ERROR CUSTOMERS", err);
		}
	};

	const updateCustomer = async (e) => {
		e.preventDefault();
		console.log("UPDATE CUSTOMER");
		try {
			var body = JSON.stringify({
				userID: id,
				name: customer.name,
				email: customer.email,
				password: customer.password,
				status: customer.status,
			});
			console.log("DATA", body);
			const response = await fetch(URL.apiUrl + "/updateCustomer", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: body,
			});

			if (!response.ok) {
				throw new Error("Error en la respuesta de la red");
			}

			const result = await response.json();
			if (response.status === 200) {
				console.log("CUSTOMER UPDATED", result);
				swal("Felicidades", result.message, "success");
				navigate("/logged/customers");
			}
		} catch (err) {
			console.log("ERROR CUSTOMERS", err);
		}
	};

	const handleInputChange = (event) => {
		setCustomer({ ...customer, [event.target.name]: event.target.value });
		setTimeout(() => {
			console.log("STATE", customer);
		}, 2000);
	};

	const handleSelectChange = (selectedOption) => {
		setCustomer({ ...customer, status: selectedOption.value });
	};

	return (
		<>
			<div className="row">
				<div className="col-xl-3 col-lg-4">
					<div className="clearfix">
						<div className="card card-bx profile-card author-profile m-b30">
							<div className="card-body">
								<div className="p-5">
									<div className="author-profile">
										<div className="author-media">
											<img src={user} alt="" />
											<div
												className="upload-link"
												title=""
												data-toggle="tooltip"
												data-placement="right"
												data-original-title="update"
											>
												<input
													type="file"
													className="update-flie"
												/>
												<i className="fa fa-camera"></i>
											</div>
										</div>
										<div className="author-info">
											<h6 className="title">
												{customer.name}
											</h6>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-xl-9 col-lg-8">
					<div className="card profile-card card-bx m-b30">
						<div className="card-header">
							<h6 className="title">Cuenta</h6>
						</div>
						<form
							className="profile-form"
							onSubmit={updateCustomer}
						>
							<div className="card-body">
								<div className="row">
									<div className="col-sm-6 m-b30">
										<label className="form-label">
											Nombre
										</label>
										<input
											type="text"
											className="form-control"
											name="name"
											value={customer.name}
											onChange={handleInputChange}
										/>
									</div>

									<div className="col-sm-6 m-b30">
										<label className="form-label">
											E-mail
										</label>
										<input
											type="text"
											className="form-control"
											name="email"
											value={customer.email}
											onChange={handleInputChange}
										/>
									</div>

									<div className="col-sm-6 m-b30">
										<label className="form-label">
											Contraseña
										</label>
										<input
											type="text"
											className="form-control"
											name="password"
											value={customer.password}
											onChange={handleInputChange}
										/>
									</div>

									<div className="col-sm-6 m-b30">
										<label className="form-label">
											Estado
										</label>
										<Select
											options={[
												{
													value: "Activo",
													label: "Activo",
												},
												{
													value: "No Activo",
													label: "No Activo",
												},
											]}
											className="custom-react-select"
											value={{
												value: customer.status,
												label: customer.status,
											}}
											onChange={handleSelectChange}
											isSearchable={false}
										/>
									</div>
								</div>
							</div>
							<div className="card-footer">
								<button
									className="btn btn-primary"
									type="submit"
								>
									Actualizar
								</button>
								<button
									className="btn btn-secondary"
									onClick={() => {
										navigate("/logged/customers");
									}}
								>
									Atras
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	);
};
export default EditProfile;
