import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

// Selecciona el contenedor donde la aplicación será montada
const container = document.getElementById("root");

// Crea el root usando la API createRoot de React 18
const root = createRoot(container);

// Renderiza la aplicación en el root
root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
);

// Llámala para medir el rendimiento de tu aplicación
reportWebVitals();
