import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BrowserRouter as Router, Routes, Route, Link, Navigate } from "react-router-dom";
import { AuthProvider, useAuth } from "./services/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartLine, faUsers, faDollarSign, faTachometerAlt } from "@fortawesome/free-solid-svg-icons";

import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import "./css/drawer.css";

import LoginScreen from "./Login";
import RegisterScreen from "./Register";
import ForgotScreen from "./Forgot";
import DashboardScreen from "./Dashboard";
import UsersScreen from "./Users";
import PaymentsScreen from "./Payments";
// import TVChartContainer from "./components/TVChartContainer/index";
// import Chart2Screen from "./Chart2";
import PrivateHeader from "./Header";
import CustomersScreen from "./Customers";
import AddCustomerScreen from "./AddCustomer";
import EditCustomerScreen from "./EditCustomer";
import CustomersGestorScreen from "./CustomersGestor";
import AddCustomerGestorScreen from "./AddCustomerGestor";
import EditCustomerGestorScreen from "./EditCustomerGestor";
import AddPaymentScreen from "./AddPayment";
import EditPaymentScreen from "./EditPayment";
import AddUserScreen from "./AddUser";
import EditUserScreen from "./EditUser";

import logo from "./assets/logoW.png";

function App() {
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);

	const toggleDrawer = () => {
		setIsDrawerOpen(!isDrawerOpen);
	};

	return (
		<AuthProvider>
			<Router>
				<div>
					<Routes>
						<Route path="/" element={<LoginScreen />} />
						<Route path="/forgot" element={<ForgotScreen />} />
						<Route path="/register" element={<RegisterScreen />} />
						<Route path="/logged/*" element={<PrivateRoutes />} />
					</Routes>
				</div>
			</Router>
		</AuthProvider>
	);
}

function Drawer({ isOpen, toggleDrawer }) {
	const navigate = useNavigate();
	const { user, logout } = useAuth();

	const handleLogout = () => {
		logout(); // Cierra la sesión del usuario
		navigate("/"); // Redirige al usuario a la página de inicio de sesión
	};

	const handleLinkClick = () => {
		toggleDrawer(); // Cerrará el drawer
	};

	return (
		<div
			className={`drawer ${isOpen ? "open" : ""} bg-secondary`}
			style={{
				borderTopRightRadius: "35px",
				borderBottomRightRadius: "35px",
				zIndex: 1000,
			}}>
			<div className="logo">
				<img src={logo} alt="Logo" style={{ maxWidth: "100%", height: "auto" }} />
			</div>
			<div className="menu">
				<Link to="/logged/dashboard" className="menu-item" onClick={handleLinkClick}>
					<FontAwesomeIcon icon={faTachometerAlt} /> Dashboard
				</Link>

				{user.type === "admin" && (
					<>
						<Link to="/logged/customers" className="menu-item" onClick={handleLinkClick}>
							<FontAwesomeIcon icon={faUsers} /> Clientes
						</Link>
						<Link to="/logged/customersGestor" className="menu-item" onClick={handleLinkClick}>
							<FontAwesomeIcon icon={faUsers} /> Clientes Gestor
						</Link>
						<Link to="/logged/payments" className="menu-item" onClick={handleLinkClick}>
							<FontAwesomeIcon icon={faDollarSign} /> Pagos
						</Link>
						<Link to="/logged/users" className="menu-item" onClick={handleLinkClick}>
							<FontAwesomeIcon icon={faUsers} /> Administradores
						</Link>
						<Link to="/logged/chart" className="menu-item" onClick={handleLinkClick}>
							<FontAwesomeIcon icon={faChartLine} /> Gráfico
						</Link>
					</>
				)}

				{/* 
				<Link to="/logged/chart2" className="menu-item" onClick={handleLinkClick}>
					<FontAwesomeIcon icon={faChartLine} /> Gráfico 2
				</Link> */}
			</div>
			<div
				className="bottom-section"
				style={{
					position: "absolute",
					bottom: 0,
					alignItems: "center",
				}}>
				<button className="btn btn-link menu-item text-light" onClick={handleLogout}>
					Cerrar Sesión
				</button>
				<p className="small-text">
					Hecho con <i className="fas fa-heart text-danger"></i> por{" "}
					<a href="https://heikin.academy" target="_blank" rel="noopener noreferrer">
						Heikin Academy
					</a>
				</p>
			</div>
			<button className="drawer-close" onClick={toggleDrawer}>
				&times;
			</button>
		</div>
	);
}

function PrivateRoutes() {
	const { user, logout } = useAuth();
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);

	const toggleDrawer = () => {
		setIsDrawerOpen(!isDrawerOpen);
	};

	if (!user) {
		return <Navigate to="/" />;
	}

	if (user.type === "admin") {
		return (
			<div className={`app ${isDrawerOpen ? "drawer-open" : ""}`}>
				{user && (
					<button
						style={{
							position: "absolute",
							top: 5,
							left: 10,
							zIndex: 999,
						}}
						className={`btn btn-secondary drawer-toggle ${isDrawerOpen ? "open" : ""}`}
						onClick={toggleDrawer}>
						☰
					</button>
				)}
				<Drawer isOpen={isDrawerOpen} toggleDrawer={toggleDrawer} />
				{/* <PrivateHeader /> */}
				<div style={{ marginTop: 80 }}></div>
				{/* Muestra el encabezado privado solo si el usuario está autenticado */}
				<Routes>
					<Route path="/dashboard" element={<DashboardScreen />} />
					{user.type === "admin" && (
						<>
							<Route path="/customers" element={<CustomersScreen />} />
							<Route path="/addCustomer" element={<AddCustomerScreen />} />
							<Route path="/editCustomer/:id" element={<EditCustomerScreen />} />
							<Route path="/customersGestor" element={<CustomersGestorScreen />} />
							<Route path="/addCustomerGestor" element={<AddCustomerGestorScreen />} />
							<Route path="/editCustomerGestor/:id" element={<EditCustomerGestorScreen />} />
							<Route path="/payments" element={<PaymentsScreen />} />
							<Route path="/addPayment" element={<AddPaymentScreen />} />
							<Route path="/editPayment/:id" element={<EditPaymentScreen />} />
							<Route path="/users" element={<UsersScreen />} />
							<Route path="/addUser" element={<AddUserScreen />} />
							<Route path="/editUser/:id" element={<EditUserScreen />} />
						</>
					)}
				</Routes>
			</div>
		);
	} else {
		return (
			<div className={`app ${isDrawerOpen ? "drawer-open" : ""}`}>
				{user && (
					<button
						style={{
							position: "absolute",
							top: 5,
							left: 10,
							zIndex: 999,
						}}
						className={`btn btn-secondary drawer-toggle ${isDrawerOpen ? "open" : ""}`}
						onClick={toggleDrawer}>
						☰
					</button>
				)}
				<Drawer isOpen={isDrawerOpen} toggleDrawer={toggleDrawer} />
				{/* <PrivateHeader /> */}
				<div style={{ marginTop: 80 }}></div>
				{/* Muestra el encabezado privado solo si el usuario está autenticado */}
				<Routes>
					<Route path="/dashboard" element={<DashboardScreen />} />
					{/* <Route path="/chart" element={<TVChartContainer />} />
					<Route path="/chart2" element={<Chart2Screen />} /> */}
				</Routes>
			</div>
		);
	}
}

export default App;
