import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import URL from "./services/Urls";

const WidgetChartIndex3 = loadable(() => pMinDelay(import("./components/WidgetChartIndex3"), 1000));

const Dashboard = () => {
	const [customers, setCustomers] = useState([]);
	const [customerMonth, setCustomerMonth] = useState(0);
	const [payments, setPayments] = useState([]);
	const [totalCustomers, setTotalCustomers] = useState(0);
	const [widgetChart, setWidgetChart] = useState([
		{ id: 1, title: "Clientes Totales", qty: 0, bgcolor: "bg-warning" },
		{ id: 2, title: "Clientes del Mes", qty: 0, bgcolor: "bg-secondary" },
		{ id: 3, title: "Montos Totales", qty: "$0", bgcolor: "bg-pink" },
		{ id: 4, title: "Montos del Mes", qty: "$0", bgcolor: "bg-primary" },
	]);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);

	useEffect(() => {
		fetchData("customersGestor").then(() => {
			fetchData("payments");
		});
		getDashboard();
	}, []);

	const fetchData = async (tableName) => {
		try {
			const response = await fetch(URL.apiUrl + "/getList", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ table: tableName }),
			});

			if (!response.ok) {
				throw new Error("Error en la respuesta de la red");
			}

			const result = await response.json();

			if (response.status === 200) {
				console.log("RESP", result);
				if (tableName == "customersGestor") {
					// dispatch(getCustomers(result.data));
					setCustomers(result.data.slice(-10));
				} else if (tableName == "payments") {
					// dispatch(getPayments(result.data));
					setPayments(result.data.slice(-10));
				}
			}
		} catch (err) {
			setError(err.message);
		} finally {
			setIsLoading(false);
		}
	};

	const getDashboard = async (tableName) => {
		try {
			const response = await fetch(URL.apiUrl + "/getDashboard", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
			});

			if (!response.ok) {
				throw new Error("Error en la respuesta de la red");
			}

			const result = await response.json();

			if (response.status === 200) {
				var data = result.data;
				console.log("DATA", data);
				var widget = [
					{
						id: 1,
						title: "Clientes Totales",
						qty: data.totalCustomers,
						bgcolor: "bg-warning",
					},
					{
						id: 2,
						title: "Clientes del Mes",
						qty: data.monthlyCustomers,
						bgcolor: "bg-secondary",
					},
					{
						id: 3,
						title: "Montos Totales",
						qty: "$" + data.totalAmount,
						bgcolor: "bg-pink",
					},
					{
						id: 4,
						title: "Montos del Mes",
						qty: "$" + data.monthlyAmount,
						bgcolor: "bg-primary",
					},
				];
				setWidgetChart(widget);
			}
		} catch (err) {
			setError(err.message);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<>
			<div className="row" style={{ paddingLeft: 100, paddingRight: 100 }}>
				<div className="col-xl-12">
					<div className="row">
						{widgetChart.map((item, ind) => (
							<div className="col-xl-3 col-lg-6 col-sm-6" key={ind}>
								<div className={`card card-box ${item.bgcolor}`}>
									<div className="card-header border-0 pb-0">
										<div className="chart-num-days">
											<p>
												<i className="fa-solid fa-sort-down me-2"></i>
												{item.title}
											</p>
											<h2 className="count-num text-white">{item.qty}</h2>
										</div>
									</div>
									<div className="card-body p-0 custome-tooltip">
										{/* <div id="widgetChart3" className="chart-primary"></div> */}
										<WidgetChartIndex3 />
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
				{/* <div className="col-xl-6">
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-header">
                  <div className="d-flex align-items-center">
                    <i className="fa-brands fa-bitcoin scale-2 text-warning"></i>
                    <h4 className="heading ms-3 mb-0">BTCUSD Perennial</h4>
                  </div>
                </div>
                <div className="card-body pt-2">
                  <div className="volume-list">
                    <div>
                      <h5>$9,125.00 USD</h5>
                      <span className="text-danger">
                        -12.04{" "}
                        <i className="fa-sharp fa-solid fa-chevron-down"></i>
                      </span>
                    </div>
                    <div>
                      <span className="text-success">High</span>
                      <h5>9,124.00 USD</h5>
                    </div>
                    <div>
                      <span className="text-danger">Low</span>
                      <h5>9,124.00 USD</h5>
                    </div>
                    <div>
                      <span className="text-success">24H Volume</span>
                      <h5>2,124 USD</h5>
                    </div>
                  </div>
                  <StockChartIndex3 />
                </div>
              </div>
            </div>
          </div>
        </div> */}

				<div className="col-xl-6">
					<div className="card">
						<div className="card-header">
							<h4 className="heading mb-0">Ultimos Clientes Registrados</h4>
						</div>
						<div className="card-body pt-0">
							<div className="table-responsive">
								<table className="table border-0 mb-0 activity-tbl">
									<thead>
										<tr className="bg-pale-dark">
											<th>Nombre</th>
											<th className="text-center">e-mail</th>
											<th>Registro</th>
											<th className="text-end">Estado</th>
										</tr>
									</thead>
									<tbody>
										{customers.map((item, ind) => (
											<tr key={ind}>
												<td>
													<Link to={"#"} className="text-primary">
														{item.name}
													</Link>
												</td>
												<td className="text-nowrap">{item.email}</td>
												<td>
													<span className="timeago text-nowrap">{item.created.split(" ")[0]}</span>
												</td>
												<td
													style={{
														textAlign: "center",
														color: "#fff",
														backgroundColor: item.status == "active" ? "lightgreen" : "red",
													}}>
													{item.status == "active" ? "Activo" : "Inactivo"}
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>

				<div className="col-xl-6">
					<div className="card">
						<div className="card-header">
							<h4 className="heading mb-0">Ultimos Pagos Registrados</h4>
						</div>
						<div className="card-body pt-0">
							<div className="table-responsive">
								<table className="table border-0 mb-0 activity-tbl">
									<thead>
										<tr className="bg-pale-dark">
											<th>Nombre</th>
											<th className="text-center">Monto</th>
											<th>Fecha</th>
											<th className="text-end">Estado</th>
										</tr>
									</thead>
									<tbody>
										{payments.map((item, ind) => (
											<tr key={ind}>
												<td>{item.name}</td>
												<td className="text-nowrap">${item.amount}</td>
												<td>
													<span className="timeago text-nowrap">{item.date}</span>
												</td>
												<td
													style={{
														textAlign: "center",
														color: "#fff",
														backgroundColor: item.status == "Pagado" ? "lightgreen" : "red",
													}}>
													{item.status}
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
export default Dashboard;
