import React, { useState, useEffect } from "react";
import { Dropdown, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import URL from "./services/Urls";
import swal from "sweetalert";

const Banking = () => {
	const [payments, setPayments] = useState([]);
	const [totalAmount, setTotalAmount] = useState(0);
	const [monthlyAmount, setMonthlyAmount] = useState(0);
	const navigate = useNavigate();

	useEffect(() => {
		getPayments();
	}, []);

	const getPayments = async () => {
		var currentDate = new Date();
		var currentMonth = String(currentDate.getMonth() + 1).padStart(2, "0"); // Los meses en JavaScript comienzan en 0
		var currentYear = currentDate.getFullYear();

		try {
			const response = await fetch(URL.apiUrl + "/getList", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ table: "payments" }),
			});

			if (!response.ok) {
				throw new Error("Error en la respuesta de la red");
			}

			const result = await response.json();
			if (response.status === 200) {
				var data = result.data;
				console.log("PAYMENTS", data);
				setPayments(data);

				// Calcular el total general
				const totalG = data.reduce(
					(acc, payment) => acc + payment.amount,
					0
				);

				// Calcular el total del mes vigente
				const totalM = data.reduce((acc, payment) => {
					const paymentDate = new Date(payment.date);
					const paymentMonth = String(
						paymentDate.getMonth() + 1
					).padStart(2, "0");
					const paymentYear = paymentDate.getFullYear();

					if (
						paymentMonth === currentMonth &&
						paymentYear === currentYear
					) {
						return acc + payment.amount;
					}
					return acc;
				}, 0);

				setTotalAmount(totalG);
				setMonthlyAmount(totalM);
			}
		} catch (err) {
			console.log("ERROR PAYMENTS", err);
		}
	};

	const handleDelete = async (item) => {
		try {
			swal({
				title: "Esta Seguro?",
				text: "Si eliminas el Cliente no vas a poder recuperarlo",
				icon: "warning",
				buttons: true,
				dangerMode: true,
			}).then(async (willDelete) => {
				if (willDelete) {
					swal("Cliente Eliminado", {
						icon: "success",
					});
					console.log("ID", item.id);
					const response = await fetch(
						URL.apiUrl + "/deleteCustomer",
						{
							method: "DELETE",
							headers: {
								"Content-Type": "application/json",
							},
							body: JSON.stringify({ userID: item.id }),
						}
					);

					if (!response.ok) {
						throw new Error("Error deleting customer");
					}
					getPayments();
				} else {
					swal("Cliente a Salvo");
				}
			});
		} catch (error) {
			console.error("Error deleting customer:", error);
		}
	};

	return (
		<>
			<div
				className="row"
				style={{ paddingLeft: 100, paddingRight: 100 }}
			>
				<div className="col-xl-12">
					<div className="row">
						<div className="col-xl-4 col-lg-4 col-sm-4">
							<div className="card prim-card">
								<div className="card-body py-3">
									<svg
										width="64"
										height="64"
										viewBox="0 0 64 64"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M45.333 9.3335H18.6663C16.191 9.3335 13.817 10.3168 12.0667 12.0672C10.3163 13.8175 9.33301 16.1915 9.33301 18.6668V45.3335C9.33301 47.8088 10.3163 50.1828 12.0667 51.9332C13.817 53.6835 16.191 54.6668 18.6663 54.6668H45.333C47.8084 54.6668 50.1823 53.6835 51.9327 51.9332C53.683 50.1828 54.6663 47.8088 54.6663 45.3335V18.6668C54.6663 16.1915 53.683 13.8175 51.9327 12.0672C50.1823 10.3168 47.8084 9.3335 45.333 9.3335ZM27.9997 14.6668H35.9997V22.6668H27.9997V14.6668ZM22.6663 49.3335H18.6663C17.6055 49.3335 16.5881 48.9121 15.8379 48.1619C15.0878 47.4118 14.6663 46.3944 14.6663 45.3335V41.3335H22.6663V49.3335ZM35.9997 49.3335H27.9997V41.3335H35.9997V49.3335ZM49.333 45.3335C49.333 46.3944 48.9116 47.4118 48.1614 48.1619C47.4113 48.9121 46.3939 49.3335 45.333 49.3335H41.333V41.3335H49.333V45.3335ZM49.333 36.0002H14.6663V18.6668C14.6663 17.606 15.0878 16.5885 15.8379 15.8384C16.5881 15.0883 17.6055 14.6668 18.6663 14.6668H22.6663V25.3335C22.6663 26.0407 22.9473 26.719 23.4474 27.2191C23.9475 27.7192 24.6258 28.0002 25.333 28.0002H49.333V36.0002ZM49.333 22.6668H41.333V14.6668H45.333C46.3939 14.6668 47.4113 15.0883 48.1614 15.8384C48.9116 16.5885 49.333 17.606 49.333 18.6668V22.6668Z"
											fill="white"
										/>
									</svg>
									<div className="d-flex">
										<h4 className="number mt-2">
											1234 5678 9012 3456
										</h4>
										<div className="rec-design">
											<div className="rec-design1"></div>
											<div className="rec-design2"></div>
										</div>
									</div>
									<div className="d-flex align-items-center justify-content-between">
										<div className="prim-info">
											<span>Card Holder</span>
											<h4>Cliente</h4>
										</div>
										<div className="master-card">
											<svg
												width="88"
												height="56"
												viewBox="0 0 88 56"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<circle
													cx="28"
													cy="28"
													r="28"
													fill="#FF5B5B"
												/>
												<circle
													cx="60"
													cy="28"
													r="28"
													fill="#F79F19"
												/>
											</svg>
											<span className="text-white d-block mt-1">
												Master Card
											</span>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="col-xl-4 col-lg-4 col-sm-4">
							<div className="card exchange">
								<div className="card-header d-block border-0 pb-0">
									<h2 className="heading"> Monto Total</h2>
								</div>
								<div className="card-body pt-0">
									<div className="balance">
										<div className="header-content">
											<h6 className="text-muted">
												Moto Total
											</h6>
										</div>
										<div className="d-flex justify-content-between align-items-baseline">
											<h4 className="count-num">
												${totalAmount}
											</h4>
											{/* <span className="fs-14 font-w400 text-success">
                        <i className="bi bi-caret-up-fill pe-2"></i>+ 15%
                      </span> */}
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="col-xxl-4 col-xl-4 col-md-4">
							<div className="card">
								<div className="card-header border-0 pb-0">
									<h2 className="heading mb-0 m-auto">
										Monto Mensual
									</h2>
								</div>
								<div className="card-body text-center pt-3">
									<div className="icon-box bg-primary">
										<svg
											width="32"
											height="32"
											viewBox="0 0 32 32"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M9 30H23C24.3261 30 25.5979 29.4732 26.5355 28.5355C27.4732 27.5979 28 26.3261 28 25V19C28 17.6739 27.4732 16.4021 26.5355 15.4645C25.5979 14.5268 24.3261 14 23 14H9C7.67392 14 6.40215 14.5268 5.46447 15.4645C4.52678 16.4021 4 17.6739 4 19V25C4 26.3261 4.52678 27.5979 5.46447 28.5355C6.40215 29.4732 7.67392 30 9 30ZM6 19C6 18.2044 6.31607 17.4413 6.87868 16.8787C7.44129 16.3161 8.20435 16 9 16H23C23.7956 16 24.5587 16.3161 25.1213 16.8787C25.6839 17.4413 26 18.2044 26 19V25C26 25.7956 25.6839 26.5587 25.1213 27.1213C24.5587 27.6839 23.7956 28 23 28H9C8.20435 28 7.44129 27.6839 6.87868 27.1213C6.31607 26.5587 6 25.7956 6 25V19Z"
												fill="white"
											/>
											<path
												d="M16 26C16.7912 26 17.5645 25.7654 18.2223 25.3259C18.8801 24.8864 19.3928 24.2616 19.6955 23.5307C19.9983 22.7998 20.0775 21.9956 19.9232 21.2196C19.7688 20.4437 19.3879 19.731 18.8285 19.1716C18.269 18.6122 17.5563 18.2312 16.7804 18.0769C16.0045 17.9225 15.2002 18.0017 14.4693 18.3045C13.7384 18.6072 13.1137 19.1199 12.6741 19.7777C12.2346 20.4355 12 21.2089 12 22C12 23.0609 12.4215 24.0783 13.1716 24.8284C13.9217 25.5786 14.9392 26 16 26ZM16 20C16.3956 20 16.7823 20.1173 17.1112 20.3371C17.4401 20.5568 17.6964 20.8692 17.8478 21.2346C17.9992 21.6001 18.0388 22.0022 17.9616 22.3902C17.8844 22.7781 17.6939 23.1345 17.4142 23.4142C17.1345 23.6939 16.7782 23.8844 16.3902 23.9616C16.0022 24.0387 15.6001 23.9991 15.2347 23.8478C14.8692 23.6964 14.5569 23.44 14.3371 23.1111C14.1173 22.7822 14 22.3956 14 22C14 21.4696 14.2107 20.9609 14.5858 20.5858C14.9609 20.2107 15.4696 20 16 20ZM16 2C15.7348 2 15.4805 2.10536 15.2929 2.29289C15.1054 2.48043 15 2.73478 15 3V8.59L12.46 6.05C12.2687 5.88617 12.0227 5.80057 11.771 5.81029C11.5193 5.82001 11.2806 5.92434 11.1025 6.10244C10.9244 6.28053 10.82 6.51927 10.8103 6.77095C10.8006 7.02262 10.8862 7.2687 11.05 7.46L15.29 11.71C15.3822 11.8 15.4908 11.8713 15.61 11.92C15.7334 11.9723 15.866 11.9992 16 11.9992C16.134 11.9992 16.2666 11.9723 16.39 11.92C16.5092 11.8713 16.6179 11.8 16.71 11.71L21 7.46C21.1639 7.2687 21.2495 7.02262 21.2397 6.77095C21.23 6.51927 21.1257 6.28053 20.9476 6.10244C20.7695 5.92434 20.5308 5.82001 20.2791 5.81029C20.0274 5.80057 19.7813 5.88617 19.59 6.05L17 8.59V3C17 2.73478 16.8947 2.48043 16.7071 2.29289C16.5196 2.10536 16.2652 2 16 2Z"
												fill="white"
											/>
										</svg>
									</div>
									<div className="mt-3">Monto Mensual</div>
									<div className="count-num mt-1">
										${monthlyAmount}
									</div>
								</div>
							</div>
						</div>

						<div className="col-xl-12">
							<div className="card">
								<div className="card-header justify-content-between border-0">
									<h2 className="heading mb-0">
										Ultimas Transacciones
									</h2>

									<div className="d-flex justify-content-end mb-3">
										<Button
											variant="primary"
											as={Link}
											to="/logged/addPayment"
										>
											Agregar Pago
										</Button>
									</div>
								</div>
								<div className="card-body pt-0 px-0">
									<div className="table-responsive">
										<table
											className="table-responsive tb-transaction table shadow-hover mb-4 dataTable no-footer"
											id="example6"
										>
											<tbody>
												{payments.map((item, index) => (
													<tr key={index}>
														<td>
															<div className="d-flex align-items-center">
																{/* <img
                                  src={item.image}
                                  alt=""
                                  className="avatar"
                                /> */}
																<div className="ms-3">
																	<h5 className="mb-0">
																		<Link
																			to={
																				"./invoice"
																			}
																			className="text-secondary"
																		>
																			{
																				item.name
																			}
																		</Link>
																	</h5>
																</div>
															</div>
														</td>
														<td className="font-w700 fs-16">
															${item.amount}
														</td>
														<td className="fs-14 font-w400">
															{item.date}
														</td>
														<td
															style={{
																textAlign:
																	"center",
																backgroundColor:
																	item.status ===
																	"Pagado"
																		? "lightgreen"
																		: "red",
															}}
														>
															{item.status}
														</td>
														<td className="text-end ps-0">
															<Dropdown className="dropdown">
																<Dropdown.Toggle
																	as="div"
																	className="btn-link btn sharp tp-btn btn-primary pill i-false"
																	data-bs-toggle="dropdown"
																	aria-expanded="false"
																>
																	<svg
																		width="24"
																		height="24"
																		viewBox="0 0 24 24"
																		fill="none"
																		xmlns="http://www.w3.org/2000/svg"
																	>
																		<path
																			d="M12.0012 9.35986C11.6543 9.35986 11.3109 9.42818 10.9904 9.56091C10.67 9.69365 10.3788 9.8882 10.1335 10.1335C9.88829 10.3787 9.69374 10.6699 9.56101 10.9903C9.42828 11.3108 9.35996 11.6542 9.35996 12.0011C9.35996 12.3479 9.42828 12.6914 9.56101 13.0118C9.69374 13.3323 9.88829 13.6234 10.1335 13.8687C10.3788 14.1139 10.67 14.3085 10.9904 14.4412C11.3109 14.5739 11.6543 14.6423 12.0012 14.6423C12.7017 14.6421 13.3734 14.3637 13.8686 13.8683C14.3638 13.3728 14.6419 12.701 14.6418 12.0005C14.6416 11.3 14.3632 10.6282 13.8677 10.133C13.3723 9.63782 12.7004 9.3597 12 9.35986H12.0012ZM3.60116 9.35986C3.25431 9.35986 2.91086 9.42818 2.59042 9.56091C2.26997 9.69365 1.97881 9.8882 1.73355 10.1335C1.48829 10.3787 1.29374 10.6699 1.16101 10.9903C1.02828 11.3108 0.959961 11.6542 0.959961 12.0011C0.959961 12.3479 1.02828 12.6914 1.16101 13.0118C1.29374 13.3323 1.48829 13.6234 1.73355 13.8687C1.97881 14.1139 2.26997 14.3085 2.59042 14.4412C2.91086 14.5739 3.25431 14.6423 3.60116 14.6423C4.30165 14.6421 4.97339 14.3637 5.4686 13.8683C5.9638 13.3728 6.24192 12.701 6.24176 12.0005C6.2416 11.3 5.96318 10.6282 5.46775 10.133C4.97231 9.63782 4.30045 9.3597 3.59996 9.35986H3.60116ZM20.4012 9.35986C20.0543 9.35986 19.7109 9.42818 19.3904 9.56091C19.07 9.69365 18.7788 9.8882 18.5336 10.1335C18.2883 10.3787 18.0937 10.6699 17.961 10.9903C17.8283 11.3108 17.76 11.6542 17.76 12.0011C17.76 12.3479 17.8283 12.6914 17.961 13.0118C18.0937 13.3323 18.2883 13.6234 18.5336 13.8687C18.7788 14.1139 19.07 14.3085 19.3904 14.4412C19.7109 14.5739 20.0543 14.6423 20.4012 14.6423C21.1017 14.6421 21.7734 14.3637 22.2686 13.8683C22.7638 13.3728 23.0419 12.701 23.0418 12.0005C23.0416 11.3 22.7632 10.6282 22.2677 10.133C21.7723 9.63782 21.1005 9.3597 20.4 9.35986H20.4012Z"
																			fill="#A098AE"
																		/>
																	</svg>
																</Dropdown.Toggle>
																<Dropdown.Menu>
																	<Dropdown.Item
																		onClick={() => {
																			navigate(
																				`/logged/editPayment/${item.id}`
																			);
																		}}
																	>
																		Editar
																	</Dropdown.Item>
																	{/* Cambia la llamada de la función handleDelete */}
																	<Dropdown.Item
																		onClick={() =>
																			handleDelete(
																				item
																			)
																		}
																		className="text-danger"
																	>
																		Borrar
																	</Dropdown.Item>
																</Dropdown.Menu>
															</Dropdown>
														</td>
													</tr>
												))}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
export default Banking;
