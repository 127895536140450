import React, { Fragment, useState, useEffect } from "react";
import { Dropdown, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import URL from "./services/Urls";
import swal from "sweetalert";

const CustomersGestor = () => {
	const [customersGestor, setCustomersGestor] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [searchTerm, setSearchTerm] = useState(""); // Estado para el término de búsqueda
	const itemsPerPage = 15;
	const navigate = useNavigate();

	useEffect(() => {
		getCustomersGestor();
	}, []);

	const getCustomersGestor = async () => {
		try {
			const response = await fetch(URL.apiUrl + "/getList", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ table: "customersGestor" }),
			});

			if (!response.ok) {
				throw new Error("Error en la respuesta de la red");
			}

			const result = await response.json();
			if (response.status === 200) {
				setCustomersGestor(result.data);
			}
		} catch (err) {
			console.log("ERROR CUSTOMERS", err);
		}
	};

	const handleDelete = async (item) => {
		try {
			swal({
				title: "Esta Seguro?",
				text: "Si eliminas el Cliente no vas a poder recuperarlo",
				icon: "warning",
				buttons: true,
				dangerMode: true,
			}).then(async (willDelete) => {
				if (willDelete) {
					swal("Cliente Eliminado", {
						icon: "success",
					});
					swal({
						title: "Cliente Eliminado",
						icon: "success",
						timer: 1000, // Tiempo en milisegundos
						buttons: false, // Ocultar el botón para que se cierre automáticamente
					});
					const response = await fetch(URL.apiUrl + "/deleteCustomerGestor", {
						method: "DELETE",
						headers: {
							"Content-Type": "application/json",
						},
						body: JSON.stringify({ userID: item.id }),
					});

					if (!response.ok) {
						throw new Error("Error deleting customer");
					}
					getCustomersGestor();
				} else {
					swal("Cliente a Salvo");
				}
			});
		} catch (error) {
			console.error("Error deleting customer:", error);
		}
	};

	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;

	// Filtrar clientes según el término de búsqueda
	const filteredCustomers = customersGestor.filter((customer) => {
		const name = customer.name ? customer.name.toLowerCase() : "";
		const email = customer.email ? customer.email.toLowerCase() : "";
		return name.includes(searchTerm.toLowerCase()) || email.includes(searchTerm.toLowerCase());
	});

	const currentItems = filteredCustomers.slice(indexOfFirstItem, indexOfLastItem);

	const drop = (item) => (
		<Dropdown>
			<Dropdown.Toggle as="div" variant="" className="btn btn-primary tp-btn-light sharp i-false">
				<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
					<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
						<rect x="0" y="0" width="24" height="24"></rect>
						<circle fill="#000000" cx="5" cy="12" r="2"></circle>
						<circle fill="#000000" cx="12" cy="12" r="2"></circle>
						<circle fill="#000000" cx="19" cy="12" r="2"></circle>
					</g>
				</svg>
			</Dropdown.Toggle>
			<Dropdown.Menu>
				<Dropdown.Item
					onClick={() => {
						navigate(`/logged/editCustomerGestor/${item.id}`);
					}}>
					Editar
				</Dropdown.Item>
				<Dropdown.Item onClick={() => handleDelete(item)} className="text-danger">
					Borrar
				</Dropdown.Item>
			</Dropdown.Menu>
		</Dropdown>
	);

	const pageNumbers = [];
	for (let i = 1; i <= Math.ceil(filteredCustomers.length / itemsPerPage); i++) {
		pageNumbers.push(i);
	}

	return (
		<Fragment>
			<div className="row" style={{ paddingLeft: 100, paddingRight: 100 }}>
				<h1 className="mb-4">Clientes Gestor</h1>
				<div className="col-lg-12">
					<div className="card">
						<div className="card-body">
							<div className="d-flex justify-content-between mb-3">
								<input
									type="text"
									placeholder="Buscar por nombre o email"
									value={searchTerm}
									onChange={(e) => setSearchTerm(e.target.value)}
									className="form-control w-50"
								/>
								<Button variant="primary" as={Link} to="/logged/addCustomerGestor">
									Agregar Cliente
								</Button>
							</div>
							<div className="table-responsive">
								<table className="table table-sm mb-0 table-striped student-tbl">
									<thead>
										<tr>
											<th>Nombre</th>
											<th>Email</th>
											<th>Cuenta MT5</th>
											<th>Registrado</th>
											<th>Estado</th>
											<th></th>
										</tr>
									</thead>
									<tbody id="customersGestor">
										{currentItems.map((item, ind) => (
											<tr className="btn-reveal-trigger" key={ind}>
												<td className="py-3">
													<Link to="/ecom-customersGestor">
														<div className="media d-flex align-items-center">
															<div className="media-body">
																<h5 className="mb-0 fs--1">{item.name}</h5>
															</div>
														</div>
													</Link>
												</td>
												<td className="py-2">
													<a href="mailto:">{item.email}</a>
												</td>
												<td className="py-2">{item.accountID}</td>
												<td className="py-2">{item.created}</td>
												<td
													style={{
														textAlign: "center",
														color: "#fff",
														backgroundColor:
															item.status === "active" ? "lightgreen" : item.status === "cheater" ? "orange" : "red",
													}}>
													{item.status === "active" ? "Activo" : "No Activo"}
												</td>
												<td className="py-2 text-end">{drop(item)}</td>
											</tr>
										))}
									</tbody>
								</table>
								<nav>
									<ul className="pagination">
										{pageNumbers.map((number) => (
											<li key={number} className={`page-item ${currentPage === number ? "active" : ""}`}>
												<button onClick={() => paginate(number)} className="page-link">
													{number}
												</button>
											</li>
										))}
									</ul>
								</nav>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default CustomersGestor;
