import React, { useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import swal from "sweetalert";

import { useAuth } from "./services/AuthContext";
import URL from "./services/Urls";

import logo from "./assets/logo.png";
import bg6 from "./assets/bg6.jpg";

function Login(props) {
	const { setAuthenticatedUser } = useAuth();
	const [heartActive, setHeartActive] = useState(true);
	const navigate = useNavigate();
	const [email, setEmail] = useState("");
	let errorsObj = { email: "", password: "" };
	const [errors, setErrors] = useState(errorsObj);
	const [password, setPassword] = useState("");

	async function onLogin(e) {
		e.preventDefault();
		let error = false;
		const errorObj = { ...errorsObj };

		if (email === "") {
			errorObj.email = "El correo es requerido";
			error = true;
		}

		if (password === "") {
			errorObj.password = "La contraseña es requerida";
			error = true;
		}

		setErrors(errorObj);

		if (error) {
			return;
		}

		const response = await fetch(URL.apiUrl + "/login", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ email, password }),
		});
		const data = await response.json();
		if (response.ok) {
			if (data.data.status !== "Activo") {
				swal(
					"Malas Noticias",
					"Su cuenta no esta activa, comuniquese con soporte",
					"warning"
				);
			} else {
				setAuthenticatedUser(data.data);
				navigate("/logged/dashboard");
			}
		} else {
			swal("Malas Noticias", data.message, "warning");

			console.error("Error al solicitar la recuperación de contraseña.");
		}
	}

	return (
		<div className="page-wraper">
			<div className="browse-job login-style3">
				<div
					className="bg-img-fix overflow-hidden"
					style={{
						background: "#fff url(" + bg6 + ")",
						height: "100vh",
					}}
				>
					<div className="row gx-0">
						<div className="col-xl-4 col-lg-5 col-md-6 col-sm-12 vh-100 bg-white ">
							<div
								id="mCSB_1"
								className="mCustomScrollBox mCS-light mCSB_vertical mCSB_inside"
								style={{ maxHeight: "653px" }}
							>
								<div
									id="mCSB_1_container"
									className="mCSB_container"
									style={{
										position: "relative",
										top: "0",
										left: "0",
										dir: "ltr",
									}}
								>
									<div className="login-form style-2">
										<div className="card-body">
											<div className="logo-header">
												<Link to={"#"} className="logo">
													<img
														src={logo}
														alt=""
														className="width-230 mCS_img_loaded"
													/>
												</Link>
											</div>
											<div className="nav nav-tabs border-bottom-0">
												<div
													className="tab-content w-100"
													id="nav-tabContent"
												>
													<div
														className="tab-pane fade active show"
														id="nav-personal"
													>
														{props.errorMessage && (
															<div className="bg-red-300 text-red-900 border border-red-900 p-1 my-2">
																{
																	props.errorMessage
																}
															</div>
														)}
														{props.successMessage && (
															<div className="bg-green-300 text-green-900 border border-green-900 p-1 my-2">
																{
																	props.successMessage
																}
															</div>
														)}
														<form
															className=" dz-form pb-3"
															onSubmit={onLogin}
														>
															<h3 className="form-title m-t0">
																Iniciar Sessión
															</h3>
															<div className="dz-separator-outer m-b5">
																<div className="dz-separator bg-primary style-liner"></div>
															</div>
															<p>
																Ingrese su
																usuario y
																contraseña.{" "}
															</p>
															<div className="form-group mb-3">
																{/* <input name="dzName" required="" className="form-control" placeholder="User Name" type="text" /> */}
																<input
																	type="email"
																	className="form-control"
																	value={
																		email
																	}
																	onChange={(
																		e
																	) =>
																		setEmail(
																			e
																				.target
																				.value
																		)
																	}
																/>
																{errors.email && (
																	<div className="text-danger fs-12">
																		{
																			errors.email
																		}
																	</div>
																)}
															</div>
															<div className="form-group mb-3">
																{/* <input name="dzName" required="" className="form-control " placeholder="Type Password" type="password" /> */}
																<input
																	type="password"
																	className="form-control"
																	value={
																		password
																	}
																	onChange={(
																		e
																	) =>
																		setPassword(
																			e
																				.target
																				.value
																		)
																	}
																/>
																{errors.password && (
																	<div className="text-danger fs-12">
																		{
																			errors.password
																		}
																	</div>
																)}
															</div>
															<div className="form-group text-left mb-5">
																<button
																	style={{
																		marginBottom: 20,
																	}}
																	type="submit"
																	className="btn btn-primary dz-xs-flex m-r5"
																>
																	Iniciar
																	Sessión
																</button>
																<Link
																	to={
																		"/forgot"
																	}
																	className="nav-link m-auto btn tp-btn-light btn-primary"
																>
																	Olvido su
																	contraseña ?
																</Link>
															</div>
														</form>
														<div className="text-center bottom">
															<NavLink
																to="/register"
																className="btn btn-primary button-md btn-block"
															>
																Crear una cuenta
															</NavLink>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="card-footer">
											<div className=" bottom-footer clearfix m-t10 m-b20 row text-center">
												<div className="col-lg-12 text-center">
													<span>
														{" "}
														© Copyright por{" "}
														<span
															className={`heart ${
																heartActive
																	? ""
																	: "heart-blast"
															}`}
															onClick={() =>
																setHeartActive(
																	!heartActive
																)
															}
														></span>
														<a
															href="https://www.dexignzone.com/"
															target="_blank"
															rel="noreferrer"
														>
															{" "}
															Heikin Academy -
														</a>{" "}
														Todos los derechos
														reservados.
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Login;
