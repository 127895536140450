import React, { useState } from "react";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import swal from "sweetalert";

import URL from "./services/Urls";

import logo from "./assets/logo.png";
import bg6 from "./assets/bg6.jpg";

function Register(props) {
	const [heartActive, setHeartActive] = useState(true);

	const [email, setEmail] = useState("");
	let errorsObj = { email: "", password: "" };
	const [errors, setErrors] = useState(errorsObj);
	const [password, setPassword] = useState("");
	const [name, setName] = useState("");

	const navigate = useNavigate();

	function onSignUp(e) {
		e.preventDefault();
		let error = false;
		const errorObj = { ...errorsObj };

		// Validate input fields
		if (name === "") {
			errorObj.name = "El nombre es requerido";
			error = true;
		}
		if (email === "") {
			errorObj.email = "El e-mail es requerido";
			error = true;
		}
		if (password === "") {
			errorObj.password = "La contraseña es requerida";
			error = true;
		}
		setErrors(errorObj);

		if (error) return;

		// Perform API call
		fetch(URL.apiUrl + "/register", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				name: name,
				email: email,
				password: password,
			}),
		})
			.then(async (response) => {
				if (response.status === 200) {
					// Successful registration
					console.log("RESPONSE 200", response);
					return response.json();
				} else if (response.status === 400) {
					// Bad request, handle validation errors
					console.log("RESPONSE 400", response);

					return response.json().then((data) => {
						throw new Error(data.error || "Error de validación");
					});
				} else {
					// Handle other status codes
					throw new Error("Error en la solicitud");
				}
			})
			.then((data) => {
				// toast.success(`✔️ ${data.message}`, {
				//   position: "top-right",
				//   autoClose: 5000,
				//   hideProgressBar: false,
				//   closeOnClick: false,
				//   pauseOnHover: true,
				//   draggable: true,
				// });
				swal("Felicidades", data.message, "success");
				navigate("/");
			})
			.catch((error) => {
				// Handle API call error
				console.error("Error en la solicitud:", error.message || error);
				toast.error(`❌ ${error.message || "Error en la solicitud"}`, {
					position: "top-right",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: false,
					pauseOnHover: true,
					draggable: true,
				});
			});
	}

	return (
		<>
			<div className="page-wraper">
				<div className="browse-job login-style3">
					<div
						className="bg-img-fix overflow-hidden"
						style={{
							background: "#fff url(" + bg6 + ")",
							height: "100vh",
						}}
					>
						<div className="row gx-0">
							<div className="col-xl-4 col-lg-5 col-md-6 col-sm-12 vh-100 bg-white">
								<div
									id="mCSB_1"
									className="mCustomScrollBox mCS-light mCSB_vertical mCSB_inside"
									style={{ maxHeight: "653px" }}
								>
									<div
										id="mCSB_1_container"
										className="mCSB_container"
										style={{
											position: "relative",
											top: "0",
											left: "0",
											dir: "ltr",
										}}
									>
										<div className="login-form style-2">
											<div className="card-body">
												<div className="logo-header">
													<Link
														to="/"
														className="logo"
													>
														<img
															src={logo}
															alt=""
															className="width-230 mCS_img_loaded"
														/>
													</Link>
												</div>
												<nav className="nav nav-tabs border-bottom-0">
													<div
														className="tab-content w-100"
														id="nav-tabContent"
													>
														<div className="tab-pane active show fade">
															{props.errorMessage && (
																<div className="">
																	{
																		props.errorMessage
																	}
																</div>
															)}
															{props.successMessage && (
																<div className="">
																	{
																		props.successMessage
																	}
																</div>
															)}
															<form
																className="dz-form py-2"
																onSubmit={
																	onSignUp
																}
															>
																<h3 className="form-title">
																	Registrar
																</h3>
																<div className="dz-separator-outer m-b5">
																	<div className="dz-separator bg-primary style-liner"></div>
																</div>
																<p>
																	Ingrese su
																	información:{" "}
																</p>
																<div className="form-group mt-3">
																	<input
																		value={
																			name
																		}
																		onChange={(
																			e
																		) =>
																			setName(
																				e
																					.target
																					.value
																			)
																		}
																		className="form-control"
																		placeholder="Nombre"
																	/>
																	{errors.email && (
																		<div className="text-danger fs-12">
																			{
																				errors.email
																			}
																		</div>
																	)}
																</div>
																<div className="form-group mt-3">
																	{/* <input name="dzName" required="" className="form-control" placeholder="Email Address" type="text" /> */}
																	<input
																		value={
																			email
																		}
																		onChange={(
																			e
																		) =>
																			setEmail(
																				e
																					.target
																					.value
																			)
																		}
																		className="form-control"
																		placeholder="E-mail"
																	/>
																	{errors.email && (
																		<div className="text-danger fs-12">
																			{
																				errors.email
																			}
																		</div>
																	)}
																</div>

																<div className="form-group mt-3">
																	{/* <input name="dzName" required="" className="form-control" placeholder="Password" type="password" /> */}
																	<input
																		value={
																			password
																		}
																		onChange={(
																			e
																		) =>
																			setPassword(
																				e
																					.target
																					.value
																			)
																		}
																		className="form-control"
																		//defaultValue="Password"
																		placeholder="Contraseña"
																	/>
																	{errors.password && (
																		<div className="text-danger fs-12">
																			{
																				errors.password
																			}
																		</div>
																	)}
																</div>
																{/* <div className="form-group mt-3 mb-3">
																	<input name="dzName" required="" className="form-control" placeholder="Re-type Your Password" type="password" />
																</div> */}
																<div className="mb-3 mt-3">
																	<span className="form-check float-start me-2">
																		<input
																			type="checkbox"
																			className="form-check-input mt-0"
																			id="check2"
																			name="example1"
																		/>
																		<label
																			className="form-check-label d-unset"
																			htmlFor="check2"
																		>
																			Estoy
																			de
																			acuerdo
																		</label>
																	</span>
																	<label>
																		<Link
																			to={
																				"#"
																			}
																		>
																			con
																			Terminos
																			y
																			Condiciones{" "}
																		</Link>
																		&amp;{" "}
																		{/* <Link to={"#"}>Privacy Policy</Link> */}
																	</label>
																</div>
																<div className="form-group clearfix text-left">
																	<NavLink
																		to="/"
																		className="btn btn-primary outline gray"
																		type="button"
																	>
																		Atras
																	</NavLink>
																	<button
																		type="submit"
																		className="btn btn-primary float-end"
																	>
																		Registrar
																	</button>
																</div>
															</form>
														</div>
													</div>
												</nav>
											</div>
											<div className="card-footer">
												<div className=" bottom-footer clearfix m-t10 m-b20 row text-center">
													<div className="col-lg-12 text-center">
														<span>
															{" "}
															© Copyright por{" "}
															<span
																className={`heart ${
																	heartActive
																		? ""
																		: "heart-blast"
																}`}
																onClick={() =>
																	setHeartActive(
																		!heartActive
																	)
																}
															></span>
															<a
																href="https://www.dexignzone.com/"
																target="_blank"
																rel="noreferrer"
															>
																{" "}
																Heikin Academy -
															</a>{" "}
															Todos los derechos
															reservados.
														</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Register;
