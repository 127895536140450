import React, { useState } from "react";
//import { Dropdown } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import URL from "./services/Urls";
import swal from "sweetalert";
import user from "./assets/face3.png";

const options2 = [
	{ value: "Activo", label: "Activo" },
	{ value: "No Activo", label: "No Activo" },
];

const AddUser = () => {
	const navigate = useNavigate();

	const [user, setUser] = useState({
		name: "",
		email: "",
		password: "",
		status: "Activo",
	});

	const handleChange = (e) => {
		setUser({ ...user, [e.target.name]: e.target.value });
	};

	const handleStatusChange = (selectedOption) => {
		setUser({ ...user, status: selectedOption.value });
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		console.log("USER", user);
		try {
			const response = await fetch(URL.apiUrl + "/registerAdmin", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					name: user.name,
					email: user.email,
					password: user.password,
				}),
			});

			const result = await response.json();
			if (response.status === 200) {
				console.log("USER UPDATED", result);
				swal("Felicidades", result.message, "success");
				navigate("/logged/users");
			} else {
				console.error("Error al agregar cliente");
			}
		} catch (error) {
			console.error("Hubo un problema con la solicitud fetch:", error);
		}
	};

	return (
		<>
			<div className="row">
				<div className="col-xl-3 col-lg-4">
					<div className="clearfix">
						<div className="card card-bx profile-card author-profile m-b30">
							<div className="card-body">
								<div className="p-5">
									<div className="author-profile">
										<div className="author-media">
											<img src={user} alt="" />
											<div
												className="upload-link"
												title=""
												data-toggle="tooltip"
												data-placement="right"
												data-original-title="update">
												<input type="file" className="update-flie" />
												<i className="fa fa-camera"></i>
											</div>
										</div>
										<div className="author-info">
											<h6 className="title">{user.name}</h6>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="col-xl-9 col-lg-8">
					<div className="card profile-card card-bx m-b30">
						<div className="card-header">
							<h6 className="title">Agregar Administrador</h6>
						</div>
						<form className="profile-form" onSubmit={handleSubmit}>
							<div className="card-body">
								<div className="row">
									<div className="col-sm-6 m-b30">
										<label className="form-label">Nombre</label>
										<input name="name" type="text" className="form-control" defaultValue={user.name} onChange={handleChange} />
									</div>

									<div className="col-sm-6 m-b30">
										<label className="form-label">Email address</label>
										<input name="email" type="text" className="form-control" defaultValue={user.email} onChange={handleChange} />
									</div>

									<div className="col-sm-6 m-b30">
										<label className="form-label">Contraseña</label>
										<input
											name="password"
											type="text"
											className="form-control"
											defaultValue={user.password}
											onChange={handleChange}
										/>
									</div>

									<div className="col-sm-6 m-b30">
										<label className="form-label">Estado</label>
										<Select
											name="status"
											options={options2}
											className="custom-react-select"
											defaultValue={options2[0]}
											isSearchable={false}
											onChange={handleStatusChange}
										/>
									</div>
								</div>
							</div>
							<div className="card-footer">
								<button className="btn btn-primary">Guardar</button>
								<button
									className="btn btn-secondary"
									onClick={() => {
										navigate("/logged/users");
									}}>
									Atras
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	);
};
export default AddUser;
