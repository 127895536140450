import React, { Fragment, useState, useEffect } from "react";
import { Dropdown, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

import URL from "./services/Urls";

import swal from "sweetalert";

const Users = () => {
	const [users, setUsers] = useState([]);
	const navigate = useNavigate();

	useEffect(() => {
		getUsers();
	}, []);

	const getUsers = async (tableName) => {
		try {
			const response = await fetch(URL.apiUrl + "/getList", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ table: "users" }),
			});

			if (!response.ok) {
				throw new Error("Error en la respuesta de la red");
			}

			const result = await response.json();
			if (response.status === 200) {
				setUsers(result.data);
			}
		} catch (err) {
			console.log("ERROR USERS", err);
		}
	};

	const handleDelete = async (item) => {
		try {
			swal({
				title: "Esta Seguro?",
				text: "Si eliminas el Cliente no vas a poder recuperarlo",
				icon: "warning",
				buttons: true,
				dangerMode: true,
			}).then(async (willDelete) => {
				if (willDelete) {
					swal("Cliente Eliminado", {
						icon: "success",
					});
					console.log("ID", item.id);
					const response = await fetch(URL.apiUrl + "/deleteUser", {
						method: "DELETE",
						headers: {
							"Content-Type": "application/json",
						},
						body: JSON.stringify({ userID: item.id }),
					});

					if (!response.ok) {
						throw new Error("Error deleting user");
					}
					getUsers();
				} else {
					swal("Cliente a Salvo");
				}
			});
		} catch (error) {
			console.error("Error deleting user:", error);
		}
	};

	const drop = (item) => (
		<Dropdown>
			<Dropdown.Toggle
				as="div"
				variant=""
				className="btn btn-primary tp-btn-light sharp i-false"
			>
				<svg
					width="24px"
					height="24px"
					viewBox="0 0 24 24"
					version="1.1"
				>
					<g
						stroke="none"
						strokeWidth="1"
						fill="none"
						fillRule="evenodd"
					>
						<rect x="0" y="0" width="24" height="24"></rect>
						<circle fill="#000000" cx="5" cy="12" r="2"></circle>
						<circle fill="#000000" cx="12" cy="12" r="2"></circle>
						<circle fill="#000000" cx="19" cy="12" r="2"></circle>
					</g>
				</svg>
			</Dropdown.Toggle>
			<Dropdown.Menu>
				<Dropdown.Item
					onClick={() => {
						navigate(`/logged/editUser/${item.id}`);
					}}
				>
					Editar
				</Dropdown.Item>
				{/* Cambia la llamada de la función handleDelete */}
				<Dropdown.Item
					onClick={() => handleDelete(item)}
					className="text-danger"
				>
					Borrar
				</Dropdown.Item>
			</Dropdown.Menu>
		</Dropdown>
	);

	return (
		<Fragment>
			{/* <PageTitle activeMenu="Shop" motherMenu="User" /> */}
			<div
				className="row"
				style={{ paddingLeft: 100, paddingRight: 100 }}
			>
				<div className="col-lg-12">
					<div className="card">
						<div className="card-body">
							<div className="d-flex justify-content-end mb-3">
								<Button
									variant="primary"
									as={Link}
									to="/logged/addUser"
								>
									Agregar Administrador
								</Button>
							</div>
							<div className="table-responsive">
								<table className="table table-sm mb-0 table-striped student-tbl">
									<thead>
										<tr>
											<th>Nombre</th>
											<th>Email</th>
											<th>Contraseña</th>
											<th>Registrado</th>
											<th>Estado</th>
											<th></th>
										</tr>
									</thead>
									<tbody id="users">
										{users.map((item, ind) => (
											<tr
												className="btn-reveal-trigger"
												key={ind}
											>
												<td className="py-3">
													<Link to="/ecom-users">
														<div className="media d-flex align-items-center">
															<div className="media-body">
																<h5 className="mb-0 fs--1">
																	{item.name}
																</h5>
															</div>
														</div>
													</Link>
												</td>
												<td className="py-2">
													<a href="mailto:ricky@example.com">
														{item.email}
													</a>
												</td>
												<td className="py-2">
													{item.password}
												</td>
												<td className="py-2">
													{item.created}
												</td>

												<td
													style={{
														textAlign: "center",
														color: "#fff",
														backgroundColor:
															item.status ===
															"Activo"
																? "lightgreen"
																: "red",
													}}
												>
													{item.status}
												</td>
												<td className="py-2 text-end">
													{drop(item)}
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default Users;
