import React, { useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { useAuth } from "./services/AuthContext";

function PrivateHeader() {
	const { user, logout } = useAuth();
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);

	const toggleDropdown = () => {
		setIsDropdownOpen(!isDropdownOpen);
	};

	const handleLogout = () => {
		logout();
		return <Navigate to="/login" />;
	};

	// Función para manejar la edición del perfil
	const handleEditProfile = () => {
		// Agrega aquí la lógica para redirigir al usuario a la página de edición de perfil
		// Por ejemplo: return <Navigate to="/edit-profile" />;
	};

	return (
		<nav className="navbar navbar-expand-lg navbar-light bg-light">
			<Link
				className="navbar-brand"
				to="/dashboard"
				style={{ marginLeft: 150 }}
			>
				Tu Logo
			</Link>
			<button
				className="navbar-toggler"
				type="button"
				data-toggle="collapse"
				data-target="#navbarNav"
				aria-controls="navbarNav"
				aria-expanded="false"
				aria-label="Toggle navigation"
			>
				<span className="navbar-toggler-icon"></span>
			</button>
			<div
				className="collapse navbar-collapse justify-content-end"
				id="navbarNav"
			>
				<ul className="navbar-nav">
					<li className="nav-item dropdown ml-auto">
						<button
							className="btn btn-link nav-link dropdown-toggle"
							id="navbarDropdown"
							data-toggle="dropdown"
							aria-haspopup="true"
							aria-expanded="false"
							onClick={toggleDropdown}
						>
							<i className="fas fa-user-circle"></i>
						</button>
						<div
							className={`dropdown-menu dropdown-menu-right ${
								isDropdownOpen ? "show" : ""
							}`}
							aria-labelledby="navbarDropdown"
						>
							<button
								className="dropdown-item"
								onClick={handleEditProfile}
							>
								Editar
							</button>
							<button
								className="dropdown-item"
								onClick={handleLogout}
							>
								Cerrar Sesión
							</button>
						</div>
					</li>
				</ul>
			</div>
		</nav>
	);
}

export default PrivateHeader;
