import React, { createContext, useContext, useState } from "react";

// Crea un contexto para el estado de autenticación
const AuthContext = createContext();

// Proveedor de autenticación que envuelve la aplicación
export const AuthProvider = ({ children }) => {
	const [user, setUser] = useState(null);

	// Función para establecer el usuario autenticado
	const setAuthenticatedUser = (userData) => {
		setUser(userData);
	};

	// Función para cerrar sesión
	const logout = () => {
		setUser(null);
	};

	return (
		<AuthContext.Provider value={{ user, setAuthenticatedUser, logout }}>
			{children}
		</AuthContext.Provider>
	);
};

// Hook personalizado para acceder al contexto de autenticación
export const useAuth = () => {
	return useContext(AuthContext);
};
