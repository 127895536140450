import React, { useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import swal from "sweetalert";

import URL from "./services/Urls";
import logo from "./assets/logo.png";

const ForgotPassword = () => {
	const navigate = useNavigate();
	const [email, setEmail] = useState("");
	const [newPass, setNewPass] = useState("");
	const [showModal, setShowModal] = useState(false);

	const handleSubmit = async (e) => {
		e.preventDefault();

		try {
			const response = await fetch(URL.apiUrl + "/forgot", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ email }),
			});
			const data = await response.json();

			if (response.ok) {
				setNewPass(data.newPassword);
				swal("Felicidades", data.message, "success");
				navigate("/");
			} else {
				console.log("DATA", data);
				swal("Malas Noticias", data.error, "warning");

				console.error(
					"Error al solicitar la recuperación de contraseña."
				);
			}
		} catch (error) {
			console.error("Error de red:", error);
		}
	};

	const closeModal = () => {
		setShowModal(false);
	};

	return (
		<div className="authincation h-100 p-meddle">
			<div className="container h-100">
				<div className="row justify-content-center h-100 align-items-center">
					<div className="col-md-6">
						<div className="authincation-content">
							<div className="row no-gutters">
								<div className="col-xl-12">
									<div className="auth-form">
										<div className="text-center mb-3">
											<Link to="/dashboard">
												<img src={logo} alt="" />
											</Link>
										</div>
										<h4 className="text-center mb-4 ">
											Olvido su Contraseña
										</h4>
										<form onSubmit={handleSubmit}>
											<div className="form-group">
												<label className="">
													<strong>Email</strong>
												</label>
												<input
													type="email"
													className="form-control"
													value={email}
													onChange={(e) =>
														setEmail(e.target.value)
													}
												/>
											</div>
											<div
												className="text-center bottom"
												style={{ paddingTop: 20 }}
											>
												<NavLink
													to="/"
													className="btn btn-primary outline gray"
													type="button"
												>
													Atras
												</NavLink>
												<button
													style={{ marginTop: 20 }}
													type="submit"
													className="btn btn-primary button-md btn-block"
												>
													Recuperar
												</button>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			{showModal && (
				<div className="modal">
					<div className="modal-content">
						<span className="close" onClick={closeModal}>
							&times;
						</span>
						<h2>Contraseña Temporal</h2>
						<p>
							Su nueva contraseña temporal es:{" "}
							<strong>{newPass}</strong>.
						</p>
						<p>
							Se recomienda cambiar la contraseña tan pronto como
							inicie sesión.
						</p>
					</div>
				</div>
			)}
		</div>
	);
};

export default ForgotPassword;
